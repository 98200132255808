import React from 'react';

function BackgroundStars(){
    return(
        <div id="star-container">
            <div id="stars"></div>
            <div id="stars2"></div>
            <div id="stars3"></div>            
        </div>
    );
}

export default BackgroundStars;