export const images=[
    {
        original: "/imgs/gallery/IMG_1458.JPG",
       
        thumbnail: "/imgs/gallery/IMG_1458.JPG",
       },
       {
        original: "/imgs/gallery/IMG_1545.JPG",
       
        thumbnail: "/imgs/gallery/IMG_1545.JPG",
       },
       {
        original: "/imgs/gallery/IMG_1582.JPG",
       
        thumbnail: "/imgs/gallery/IMG_1582.JPG",
       },
       {
        original: "/imgs/gallery/IMG_1629.JPG",
       
        thumbnail: "/imgs/gallery/IMG_1629.JPG",
       },
       {
        original: "/imgs/gallery/IMG_1638.JPG",
       
        thumbnail: "/imgs/gallery/IMG_1638.JPG",
       },
       {
        original: "/imgs/gallery/IMG_1725.JPG",
       
        thumbnail: "/imgs/gallery/IMG_1725.JPG",
       },
       {
        original: "/imgs/gallery/IMG_1736.JPG",
       
        thumbnail: "/imgs/gallery/IMG_1736.JPG",
       },
       {
        original: "/imgs/gallery/IMG_1750.JPG",
       
        thumbnail: "/imgs/gallery/IMG_1750.JPG",
       },
       {
        original: "/imgs/gallery/IMG_1751.JPG",
       
        thumbnail: "/imgs/gallery/IMG_1751.JPG",
       },
       {
        original: "/imgs/gallery/IMG_1752.JPG",
       
        thumbnail: "/imgs/gallery/IMG_1752.JPG",
       },
       {
        original: "/imgs/gallery/IMG_2400.JPG",
       
        thumbnail: "/imgs/gallery/IMG_2400.JPG",
       },
       {
        original: "/imgs/gallery/IMG_2401.JPG",
       
        thumbnail: "/imgs/gallery/IMG_2401.JPG",
       },
       {
        original: "/imgs/gallery/IMG_2425.JPG",
       
        thumbnail: "/imgs/gallery/IMG_2425.JPG",
       },
       {
        original: "/imgs/gallery/IMG_2435.JPG",
       
        thumbnail: "/imgs/gallery/IMG_2435.JPG",
       },
       {
        original: "/imgs/gallery/IMG_2436.JPG",
       
        thumbnail: "/imgs/gallery/IMG_2436.JPG",
       },
       {
        original: "/imgs/gallery/IMG_2449.JPG",
       
        thumbnail: "/imgs/gallery/IMG_2449.JPG",
       },
       {
        original: "/imgs/gallery/IMG_2454.JPG",
       
        thumbnail: "/imgs/gallery/IMG_2454.JPG",
       },
       {
        original: "/imgs/gallery/IMG_2476.JPG",
       
        thumbnail: "/imgs/gallery/IMG_2476.JPG",
       },
       {
        original: "/imgs/gallery/IMG_2505.JPG",
       
        thumbnail: "/imgs/gallery/IMG_2505.JPG",
       },
       {
        original: "/imgs/gallery/IMG_2506.JPG",
       
        thumbnail: "/imgs/gallery/IMG_2506.JPG",
       },
       {
        original: "/imgs/gallery/IMG_2510.JPG",
       
        thumbnail: "/imgs/gallery/IMG_2510.JPG",
       },
       {
        original: "/imgs/gallery/IMG_2650.JPG",
       
        thumbnail: "/imgs/gallery/IMG_2650.JPG",
       },
       {
        original: "/imgs/gallery/IMG_2668.JPG",
       
        thumbnail: "/imgs/gallery/IMG_2668.JPG",
       },
       {
        original: "/imgs/gallery/IMG_2669.JPG",
       
        thumbnail: "/imgs/gallery/IMG_2669.JPG",
       },
       {
        original: "/imgs/gallery/IMG_2690.JPG",
       
        thumbnail: "/imgs/gallery/IMG_2690.JPG",
       },
       {
        original: "/imgs/gallery/IMG_2714.JPG",
       
        thumbnail: "/imgs/gallery/IMG_2714.JPG",
       },
       {
        original: "/imgs/gallery/IMG_2748.JPG",
       
        thumbnail: "/imgs/gallery/IMG_2748.JPG",
       },
       {
        original: "/imgs/gallery/IMG_2770.JPG",
       
        thumbnail: "/imgs/gallery/IMG_2770.JPG",
       },
       {
        original: "/imgs/gallery/IMG_2854.JPG",
       
        thumbnail: "/imgs/gallery/IMG_2854.JPG",
       },
       {
        original: "/imgs/gallery/IMG_2855.JPG",
       
        thumbnail: "/imgs/gallery/IMG_2855.JPG",
       },
       {
        original: "/imgs/gallery/IMG_2856.JPG",
       
        thumbnail: "/imgs/gallery/IMG_2856.JPG",
       },
       {
        original: "/imgs/gallery/IMG_2859.JPG",
       
        thumbnail: "/imgs/gallery/IMG_2859.JPG",
       },
       {
        original: "/imgs/gallery/IMG_2863.JPG",
       
        thumbnail: "/imgs/gallery/IMG_2863.JPG",
       },
       {
        original: "/imgs/gallery/IMG_2865.JPG",
       
        thumbnail: "/imgs/gallery/IMG_2865.JPG",
       },
       {
        original: "/imgs/gallery/IMG_2874.JPG",
       
        thumbnail: "/imgs/gallery/IMG_2874.JPG",
       },
       {
        original: "/imgs/gallery/IMG_2879.JPG",
       
        thumbnail: "/imgs/gallery/IMG_2879.JPG",
       },
       {
        original: "/imgs/gallery/IMG_2893.JPG",
       
        thumbnail: "/imgs/gallery/IMG_2893.JPG",
       },
       {
        original: "/imgs/gallery/IMG_2920.JPG",
       
        thumbnail: "/imgs/gallery/IMG_2920.JPG",
       },
       {
        original: "/imgs/gallery/IMG_2924.JPG",
       
        thumbnail: "/imgs/gallery/IMG_2924.JPG",
       },
]

