import React from 'react';
import EventTile from './EventTile';

// import '../scss/event_tile.scss';
import events from '../../data/events.json';
import TempBack from "../Backgrounds/Shuttle";
import ComingSoon from "../ComingSoon";


function EventMenu(props) {

    return (
        <div>
            <div className="page-heading">
                <div className="space">
                    <div className="space-container">

                        <div className="planet mine"></div>
                        {/* <h1>NSSC EVENTS</h1> */}
                    </div>
                </div>
                <h1 className="event-title">NSSC EVENTS</h1>
            </div>

            {
                props.isActive && events.map((event, index) => <EventTile key={index} eventName={event.eventName}
                                                                          eventImg={event.eventImg}
                                                                          eventDesc={event.eventDesc}
                                                                          eventLink={event.eventLink}/>)
            }

        </div>
    );
}

export default EventMenu;
