import React from 'react';
import {Link} from 'react-router-dom';
import ImageGallery from 'react-image-gallery';
import ToggleNav from '../../components/ToggleNav';
import {images} from "../../data/images"
// import '../gallery';
// import '../gallery.css';

function Gallery(){
    return(
        <div className="gallery1">
            <Link to="/">
   <button className="back">
        <i className="fa fa-arrow-left"></i>
    </button>
   </Link> 
            {/* <link rel="stylesheet" href="../gallery.css" /> */}
            {/* <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.5.0/css/bootstrap.min.css" integrity="sha384-9aIt2nRpC12Uk9gS9baDl411NQApFmC26EwAOH8WgZl5MYYxFfc+NcPb1dKGj7Sk" crossorigin="anonymous" />
            <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/Swiper/3.4.0/css/swiper.min.css" />
            <link href='https://fonts.googleapis.com/css?family=Lato:300,400,700' rel='stylesheet' type='text/css' /> */}

            
            {/* <script src="https://code.jquery.com/jquery-3.5.1.slim.min.js" integrity="sha384-DfXdz2htPH0lsSSs5nCTpuj/zy4C+OGpamoFVy38MVBnE+IbbVYUew+OrCXaRkfj" crossorigin="anonymous"></script>
            <script src="https://cdn.jsdelivr.net/npm/popper.js@1.16.0/dist/umd/popper.min.js" integrity="sha384-Q6E9RHvbIyZFJoft+2mJbHaEWldlvI9IOYy5n3zV9zzTtmI3UksdQRVvoxMfooAo" crossorigin="anonymous"></script>
            <script src="https://stackpath.bootstrapcdn.com/bootstrap/4.5.0/js/bootstrap.min.js" integrity="sha384-OgVRvuATP1z7JjHLkuOU7Xw704+h835Lr+6QL9UvYjZE3Ipu6Tp75j7Bh/kR0JKI" crossorigin="anonymous"></script>
            <script src="https://cdnjs.cloudflare.com/ajax/libs/Swiper/3.4.0/js/swiper.min.js"></script> */}
            {/* <script src="../gallery.js"></script> */}

            <div id='stars'></div>
          <div id='stars2'></div>
          <div id='stars3'></div>

          <ToggleNav />


         <div className="gallery-inner">
         <h1>Gallery</h1>
          <br />
          <br />
        <ImageGallery items={images} />

          </div>
          

        </div>
    );
}

export default Gallery;
