import ToggleNav from '../components/ToggleNav';
import "aos/dist/aos.css";
import React, {useEffect} from 'react';
import ComingSoon from "../components/ComingSoon";
import AOS from "aos";

function StudentAmbassador() {
    const isActive = true;

    useEffect(() => {
        AOS.init();
        AOS.refresh();
    });

    if (!isActive) {
        return <ComingSoon title={'Events'}/>
    }
    return (
        <div className="event-page">
            <div id="back"></div>
            <div>
                {/* <BackgroundStars /> */}
                <div id="stars"></div>
                <div id="stars2"></div>
                <div id="stars3"></div>

                <ToggleNav/>
                    <div className="event-page-inner">
                        <div className="page-heading">
                            <h1>STUDENT AMBASSADOR</h1>
                        </div>
                        <div className="event-info">
                            <h2 data-aos="fade-right" data-aos-duration="700">INTRODUCTION</h2>
                            <ul>
                                <li data-aos={"fade-left"} data-aos-duration={"700"}>National Students’ Space Challenge
                                    2021 launches the Student Ambassador Programme to
                                    appoint the best people across the nation to spread awareness and knowledge related
                                    to space science and space technology.

                                </li>
                                <li data-aos={"fade-left"} data-aos-duration={"700"}>Student Ambassador is the student
                                    representative of an Institute to the organising
                                    team of National Students’ Space Challenge, IIT Kharagpur.

                                </li>
                                <li data-aos={"fade-left"} data-aos-duration={"700"}>He/she will be responsible for
                                    promotion, branding and marketing of National
                                    Students’ Space Challenge 2021 in their respective institute and city.

                                </li>
                                <li data-aos={"fade-left"} data-aos-duration={"700"}>Eligibility Criteria: He/she must
                                    be a student of a college under an established
                                    university.

                                </li>
                                <li data-aos={"fade-left"} data-aos-duration={"700"}>He/she will represent their college
                                    in National Students’ Space Challenge 2021, IIT
                                    Kharagpur.
                                </li>
                            </ul>
                        </div>
                        <div className="event-info">
                            <h2 data-aos="fade-right" data-aos-duration="700">PERKS OF BEING A STUDENT AMBASSADOR</h2>
                            <ul>
                                <li data-aos={"fade-left"} data-aos-duration={"700"}>Upon completion of the assigned tasks, the Student Ambassador will receive a Certificate
                                    of
                                    Recognition as Student Ambassador, National Students’ Space Challenge 2021 certified by
                                    Chairman, Kalpana Chawla Space Technology Cell, the official contact point of ISRO in
                                    IIT
                                    Kharagpur.

                                </li>
                                <li data-aos={"fade-left"} data-aos-duration={"700"}>For working as a Student Ambassador, you will be presented with exciting prizes worth up
                                    to
                                    INR 5,000/- subject to the performance of your work as a Student Ambassador of the fest.

                                </li>
                                <li data-aos={"fade-left"} data-aos-duration={"700"}>Top 10 Student Ambassadors will get special recognition through official social media
                                    pages
                                    of NSSC and a special Certificate of Appreciation.

                                </li>
                                <li data-aos={"fade-left"} data-aos-duration={"700"}>As a Student Ambassador if you can bring participation of more than 15 students
                                    (excluding
                                    the Ambassador), you will be exempted from paying any Registration Fee for the fest.

                                </li>
                                <li data-aos={"fade-left"} data-aos-duration={"700"}>The top-performing Student Ambassador will be adjudged the “Best Student Ambassador” and
                                    be
                                    rewarded with a plethora of prizes

                                </li>
                            </ul>
                        </div>
                        {
                            <div className="event-info">
                                <h2 data-aos="fade-right" data-aos-duration="700">BENEFITS OF BEING A STUDENT AMBASSADOR</h2>
                                <ul>
                                    <li data-aos={"fade-left"} data-aos-duration={"700"}>Experience of working for India’s Largest Space Technology Fest .

                                </li>
                                <li data-aos={"fade-left"} data-aos-duration={"700"}>Opportunity to work with the best people across the nation under the Student Ambassador
                                    Programme.

                                </li>
                                <li data-aos={"fade-left"} data-aos-duration={"700"}>Increase your contacts while working with people from diverse fields across the nation.

                                </li>
                                <li data-aos={"fade-left"} data-aos-duration={"700"}>Develop your Organisational and Branding skills by means of the work assigned to you.

                                </li>
                                <li data-aos={"fade-left"} data-aos-duration={"700"}>Get a chance to be the face of your college and work with the student community of IIT
                                    Kharagpur.

                                </li>
                                <li data-aos={"fade-left"} data-aos-duration={"700"}>Stand a chance to win IIT Kharagpur's National Students’ Space Challenge 2021’s Best
                                    Contingent Trophy for the best college performance in the competitions and events.
                                </li>
                                </ul>
                            </div>
                        }
                        <div className="event-info">
                            <h2 data-aos="fade-right" data-aos-duration="700">FUNCTIONS OF A STUDENT AMBASSADOR</h2>
                            <ul>
                                <li data-aos={"fade-left"} data-aos-duration={"700"}> To conduct certain pre-fest promotional events and workshops in their campus in order to
                                    cultivate interest about the fest among students.

                                </li>
                                <li data-aos={"fade-left"} data-aos-duration={"700"}>To publicise NSSC 2021 in the student community inside the campus and circulate the news
                                    through word of mouth, SEMINARS, posters, mails and online publicity via social media
                                    platforms in their city and especially in their college(if they are following the offline
                                    mode of college).

                                </li>
                                <li data-aos={"fade-left"} data-aos-duration={"700"}>Student Ambassadors provide a direct link between the organising team of NSSC and the
                                    students of the college. This will give an opportunity to directly interact with a mass of
                                    students from different colleges across the country. The task of conducting introductory
                                    seminars for NSSC, distribution of Publicity Materials to potential participants and
                                    organising workshops at the college level.

                                </li>
                                <li data-aos={"fade-left"} data-aos-duration={"700"}>Publicity at different colleges across India is carried out by the Student Ambassadors. They
                                    are responsible for sharing all the details of the fest with the students of the college.
                                </li>
                            </ul>
                        </div>
                        <div className="event-info">
                            <h2 data-aos="fade-right" data-aos-duration="700">TERMS AND CONDITIONS</h2>
                            <ul>
                                <li data-aos={"fade-left"} data-aos-duration={"700"}>The Promotion and Branding provided by Student Ambassadors will be simulated, processed and
                                    judged by team NSSC and hence the benefits will be subjected to only those Student
                                    Ambassadors, who bring a minimum participation of 15 students (Excluding the Ambassador)
                                    from his/her respective college.
                                </li>
                                <li data-aos={"fade-left"} data-aos-duration={"700"}>The Certificate and Gifts & Goodies for the Student Ambassadors will be provided during the
                                    Closing Ceremony of the Fest.
                                </li>
                                <li data-aos={"fade-left"} data-aos-duration={"700"}>Team NSSC has full rights to take decisions regarding the rewarding the Student
                                    Ambassadors.
                                </li>
                            </ul>
                        </div>
                </div>

            </div>
        </div>

    );
}

export default StudentAmbassador;
