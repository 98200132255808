import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import Spons from '../../components/Sponsors/Spons';
import ToggleNav from '../../components/ToggleNav';
import spons from '../../data/spons.json';
import shipToggle from "../../util/ship_toggle";
// import '../scss/event_page.scss';
// import '../scss/background_stars.scss';
// import '../scss/moon.scss';

function EventPage(props){

    const [year, setYear] = useState(spons.y2021);

    return(
        <div className="sponsor-page">

           


            {/* <div id='moon'>
                <div class='craterCon'>
                    <div class='craters'></div>
                </div>
                <div class='glow'></div>
            </div>             */}
            {/* <div className="back-section">
               <Link to="/">
                    <div className="back">Back</div>
               </Link>
            </div> */}
            <Link to="/">
   <button className="back">
        <i className="fa fa-arrow-left"></i>
    </button>
   </Link>
           <div>
           <div id="stars"></div>
            <div id="stars2"></div>
            <div id="stars3"></div>  

            <ToggleNav />

                <div className="spons-years">
                        <div className="year" onClick={()=>{setYear(spons.y2021)}}>2021</div>
                        <div className="year" onClick={()=>{setYear(spons.y2019)}} style={{borderRight: "1px solid"}}>2019</div>
                        <div className="year" onClick={()=>{setYear(spons.y2018)}}>2018</div>
                    </div>
                    <Spons spons={year} />
           </div>
        </div>
    );
}

export default EventPage;
