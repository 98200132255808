import React from 'react';
import {Link} from 'react-router-dom';
import Event from '../../../components/Event/DetailPage/Event';
import ToggleNav from '../../../components/ToggleNav';
// import '../scss/event_page.scss';
// import '../scss/background_stars.scss';
// import '../scss/moon.scss';

function EventPage(props){
    return(
        <div className="event-page">

            <div id="stars"></div>
            <div id="stars2"></div>
            <div id="stars3"></div> 

            <ToggleNav />

            <Link to="/events">
   <button className="back">
        <i className="fa fa-arrow-left"></i>
    </button>
   </Link> 


            {/* <div id='moon'>
                <div class='craterCon'>
                    <div class='craters'></div>
                </div>
                <div class='glow'></div>
            </div>             */}

            <Event event={props.event}/>
        </div>
    );
}

export default EventPage;
