import ToggleNav from '../components/ToggleNav';
import "aos/dist/aos.css";
import React, {useEffect,useState} from 'react';
import ComingSoon from "../components/ComingSoon";
import AOS from "aos";
import {API_URL} from "../constants";
import moment from 'moment';
import {Link,useHistory} from 'react-router-dom';
import "../scss/_dashboard.scss";

function Dashboard() {
    const isActive = true;
    const [user,setUser] = useState(undefined);
    const history = useHistory();

    useEffect(() => {
        AOS.init();
        AOS.refresh();
        fetch(`${API_URL}/api/login`, {
            method: 'GET',
            headers: {
                'x-auth-token': localStorage.getItem('jwt')
            },
        })
        .then((response) =>
        { 
            if(response.status === 200) {
                return response.json();
            }
            else {
                localStorage.removeItem('jwt');
                history.push('/login');
            }
        }
        )
        .then((data) => {
            data.dob= moment.utc(data.dob).format('DD-MM-YYYY');
            setUser(data);
        }
        )
    });

    if (!isActive) {
        return <ComingSoon title={'Events'}/>
    }

    function signOut() {
        localStorage.removeItem('jwt');
        history.push('/login');
    }

    return (
        <div className="event-page">
            <div id="back"></div>
            <div>
                {/* <BackgroundStars /> */}
                <div id="stars"></div>
                <div id="stars2"></div>
                <div id="stars3"></div>

                <ToggleNav/>

                <Link to="/">
                    <button className="back">
                        <i className="fa fa-arrow-left"></i>
                    </button>
                </Link>

                    <div className="event-page-inner">
                        <div className="page-heading">
                            <h1>{user?.name}</h1>
                        </div>
                        <img id="mobile" data-aos="fade-right" data-aos-duration="900" src={user?.avatar} alt="mobile" className="dashboard-img"/>
                        <div className="event-info">
                            <h2 data-aos="fade-right" data-aos-duration="700">{user?.email}</h2>
                            <ul>
                            <li data-aos={"fade-left"} data-aos-duration={"700"}>
                                    PID: {user?.id}
                                </li>
                                <li data-aos={"fade-left"} data-aos-duration={"700"}>
                                    Date of Birth: {user?.dob}
                                </li>
                                {user?.department!==undefined&&
                                <li data-aos={"fade-left"} data-aos-duration={"700"}>
                                    Department: {user?.department}
                                </li>
                                }
                                <li data-aos={"fade-left"} data-aos-duration={"700"}>
                                    College/School: {user?.college}
                                </li>
                                <li data-aos={"fade-left"} data-aos-duration={"700"}>
                                    Year of Graduation: {user?.yog}
                                </li>
                                <li data-aos={"fade-left"} data-aos-duration={"700"}>
                                    Address: {user?.address}
                                </li>
                                <li data-aos={"fade-left"} data-aos-duration={"700"}>
                                    Contact: {user?.contact}
                                </li>
                                <li data-aos={"fade-left"} data-aos-duration={"700"}>
                                    Event Payment Status: {user?.eventPayment ? "Done" : "Not Done"}
                                </li>
                                <li data-aos={"fade-left"} data-aos-duration={"700"}>
                                    Workshop Payment Status: {user?.workshopPayment ? "Done" : "Not Done"}
                                </li>
                                
                            </ul>
                            <Link to={`/merchandise`}>
                                <button data-aos="fade-left" data-aos-duration="700" onClick={function(){window.scrollTo(0, 0);}} className="event-button">Buy Merch</button>
                            </Link>
                            <br></br>
                            <br></br>
                            <button data-aos="fade-left" data-aos-duration="700" onClick={signOut} className="event-button">Sign Out</button>

                        </div>
                        
            </div>
        </div>
        </div>

    );
}

export default Dashboard;