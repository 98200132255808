import React, {useEffect} from 'react';
import Login from '../../components/Login/Login';
import { useHistory } from "react-router-dom";
import ComingSoon from "../../components/ComingSoon";
import shipToggle from "../../util/ship_toggle";
// import '../scss/login_page.scss';
// import '../scss/background_stars.scss';


function LoginPage(){
    console.log("login");

    // useEffect(() => {
    //     shipToggle(true);
    // },[])

    let history = useHistory();

    const isActive = true;

    useEffect(() => {
        if(localStorage.hasOwnProperty('jwt')) {
            history.push("/dashboard");
        }
    })

    if(!isActive){
        return <ComingSoon title={''}/>
    }

    return(
        <div className="login-part">
            <div id="stars"></div>
            <div id="stars2"></div>
            <div id="stars3"></div>  

            
            <Login />
        </div>
    );
}

export default LoginPage;
