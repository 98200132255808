import React,{useEffect} from 'react';
import SponsSection from './SponsSection';
// import spons from '../data/spons.json';
import AOS from "aos";
import "aos/dist/aos.css";
import shipToggle from "../../util/ship_toggle";

function Spons(props){

    useEffect(() => {
        AOS.init();
        AOS.refresh();
    });

    // useEffect(() => {
    //     shipToggle(false);
    // },[])

    return(
        <div className="sponsor">
            <div className="sponsor-inner">
                <h1>sponsors {props.spons.year}</h1>

                <div>
                    {props.spons.sponsors.map((sponsor,i) => <SponsSection 
                        key={i}
                        year={props.spons.year}
                        heading={sponsor.heading}
                        imgs={sponsor.imgs}
                    />)}
                </div>

            </div>
        </div>
    );
}

export default Spons;
