import React,{useEffect} from 'react';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import Linkify from 'linkify-react';
// import PhoneAndroidIcon from '@material-ui/icons/PhoneAndroid';
import HeadsetMicIcon from '@material-ui/icons/HeadsetMic';
import AOS from "aos";
import "aos/dist/aos.css";

function Event(props){

    useEffect(() => {
        AOS.init();
        AOS.refresh();
    });

    return(
        <div className="event-page-inner">
            {props.event.map((event)=>
             {
            return (<div>
             <div className="page-heading">
                <div className="space">
                    <div className="space-container">
                        
                        <div className="planet mine"></div>
                        {/* <h1>NSSC EVENTS</h1> */}
                    </div>
                </div>
                <h1 >{event.eventName}</h1>
            </div>
            <div className="event-info">
                <h2 data-aos="fade-right" data-aos-duration="700">INTRODUCTION</h2>
                <p data-aos="fade-left" data-aos-duration="700">{event.intro}</p>
            </div>
            {
                typeof event.ps !== 'undefined' && typeof event.ps !== 'string' && <div className="event-info">
                    <h2 data-aos="fade-right" data-aos-duration="700">PROBLEM STATEMENT</h2>
                    {
                        event.ps.map((ps)=>{
                    return (<p data-aos="fade-left" data-aos-duration="700">
                        {ps.desc}
                        <span> {
                            ps.link && <a href={ps.link} target="_blank" rel="noopener noreferrer">Download</a>
                        }</span>
                    </p>);
                        })
                    }
                </div>
            }
            {
                event.info.map((info,index) => <div key={index} className="event-info">
                    <h2 data-aos="fade-right" data-aos-duration="700">{info.heading}</h2>
                    <ul>
                        {info.desc.map((list,index) => <Linkify><li key={index} data-aos="fade-left" data-aos-duration="700">{list}</li></Linkify>)}
                    </ul>
                </div>)
            }
            <div className="event-info">
            <h2 data-aos="fade-right" data-aos-duration="700">CONTACT</h2>
                <div className="contact-detail">
                        <p data-aos="fade-left" data-aos-duration="700"><MailOutlineIcon />Mail Us at <span>{event.contactMail}</span></p>
                    {event.contact.map((head,index) => <p key={index} data-aos="fade-left"><HeadsetMicIcon />{head.name} : <span>{head.phone}</span></p>)}
                </div>
            </div>
            </div>);
        })}
        </div>
    );
}

export default Event;
