import React from 'react';
import {Link} from 'react-router-dom';
import ToggleNav from '../ToggleNav';
import PersonIcon from '@material-ui/icons/Person';
import EmailIcon from '@material-ui/icons/Email';
import RecentActorsIcon from '@material-ui/icons/RecentActors';
import EventIcon from '@material-ui/icons/Event';
import SchoolIcon from '@material-ui/icons/School';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import ContactsIcon from '@material-ui/icons/Contacts';
import PhoneIcon from '@material-ui/icons/Phone';
import LockIcon from '@material-ui/icons/Lock';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import WorkIcon from '@material-ui/icons/Work';
import {API_URL} from '../../constants'

const handleSubmit = (e) => {
    e.preventDefault();
    const { name, email, password, collegeID, dob, department, college, yog, address, contact, saID } = e.target;
    const data = {
        name: name.value,
        email: email.value,
        password: password.value,
        collegeID: collegeID.value,
        dob: dob.value,
        department: department.value,
        college: college.value,
        yog: yog.value,
        address: address.value,
        contact: contact.value,
        saID: saID.value
    }

    fetch(`${API_URL}/api/register`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
    })
    .then(async res => {
        let data= await res.json();
        console.log(data);
        if(res.status === 200) {
            window.location.href = '/login';
        }
        else {
            alert(data.errors[0].msg);
        }
    }, err => {
        console.log(err);
    });
}

function SignUp(){
    return(
        <div className="signup">

             {/* <img className="cloud2" src="/imgs/login/cloud2.svg" alt=""/> */}
            

            <Link to="/login">
            <button className="back">
                    <i className="fa fa-arrow-left"></i>
                </button>
            </Link>

            <ToggleNav />

            <div className="signup-inner">
           
                <div className="signup-detail">


                    <div className="signup-img">
                        {/* <div className="signup-logo"> */}
                            {/* <h1>"Exoplanets"</h1> */}
                            {/*<img src="/imgs/login/loginimage.svg" alt=""/>*/}
                            {/* <h2>Worlds Of Possibilities</h2> */}
                        {/* </div> */}
                    </div>


                    <img src="/imgs/login/clouds.svg" alt=""/>
                    <img className="cloud3" src="/imgs/login/cloud3.svg" alt=""/>

                    <div className="signup">
                        <h1>NSSC 2021</h1>
                        <div className="signup-info">
                            <h2>
                                Sign Up
                            </h2>
                            <h4>Instructions</h4>
                            <p><b>Registration is completely free for all students.</b></p>
                            <form onSubmit={handleSubmit}>
                               <div className="form-section">
                               <div className="inner-section">

                                    <div className="field">
                                       <div className="label">
                                            < PersonIcon/>
                                            <label>Name:</label>
                                       </div>
                                        <div className="input">    
                                            
                                            <input type="text" name="name" id="" placeholder="full name" required />     
                                        </div>
                                    </div>

                                    <div className="field">
                                    <div className="label">
                                        <EmailIcon />
                                        <label>Email:</label>
                                        </div>
                                        <div className="input">
                                           
                                            <input type="email" name="email" id="" placeholder="sample@gmail.com" required />
                                        </div>
                                    </div>

                                    <div className="field">
                                    <div className="label">
                                    <RecentActorsIcon />
                                        <label>College Id(Student Roll Number):</label>
                                        </div>

                                        <div className="input">
                                            
                                            <input type="text" name="collegeID" id="" placeholder="college ID" required />
                                        </div>
                                    </div>

                                    <div className="field">
                                    <div className="label">
                                    <EventIcon />
                                         <label>Date of Birth:</label>
                                         </div>
                                         
                                        <div className="input">
                                           
                                            <input type="date" name="dob" id="" placeholder="date of birth" required />
                                        </div>
                                    </div>

                                    <div className="field">
                                    <div className="label">
                                    <WorkIcon />
                                        <label>Department:</label>
                                        </div>
                                        <div className="input">
                                            
                                            <input type="text" name="department" id="" placeholder="department name" required />
                                        </div>
                                    </div>

                                    <div className="field">
                                    <div className="label">
                                    <SchoolIcon />
                                        <label>College:</label>
                                        </div>
                                        <div className="input">
                                            
                                            <input type="text" name="college" id="" placeholder="college name" required />
                                        </div>
                                    </div>

                                </div>
                            

                                <div className="inner-section">

                                    <div className="field">
                                    <div className="label">
                                    <EventAvailableIcon />
                                        <label>Year of Graduation:</label>
                                        </div>
                                        <div className="input">
                                            
                                            
                                            <input type="number" name="yog" min="2010" max="2030" maxLength="4" minLength="4" id="" placeholder="Your Year of Graduation" required />
                                        </div>
                                    </div>

                                    <div className="field">
                                    <div className="label">
                                    <ContactsIcon />
                                        <label>Address:</label>
                                        </div>
                                        <div className="input">
                                            
                                            <input type="text" name="address" id="" placeholder="address" required />
                                        </div>
                                    </div>

                                    <div className="field">
                                    <div className="label">
                                    <PhoneIcon />
                                        <label>Contact Number:</label>
                                        </div>
                                        <div className="input">
                                            
                                            <input type="number" name="contact" id="" minLength="10" maxLength="10" placeholder="Enter your 10 digit contact number" required />
                                        </div>
                                    </div>

                                    <div className="field">
                                    <div className="label">
                                    <LockIcon />
                                        <label>Password:</label>
                                        </div>
                                        <div className="input">
                                            
                                            <input type="password" name="password" id="" placeholder="" required />
                                        </div>
                                    </div>

                                    <div className="field">
                                    <div className="label">
                                    <LockIcon />
                                        <label>Confirm Password:</label>
                                        </div>
                                        <div className="input">
                                            
                                            <input type="password" name="" id="" placeholder="" required />
                                        </div>
                                    </div>

                                    <div className="field">
                                    <div className="label">
                                    <AssignmentIndIcon />
                                        <label>Student Ambassador ID:</label>
                                        </div>
                                        <label className="remark">*Leave blank if Student Ambassador is not associated</label>
                                        <div className="input">
                                            
                                            <input type="text" name="saID" id="" placeholder="student ambassador ID"  />
                                        </div>
                                    </div>
                                </div>

                               </div>
                                <button type="submit">Sign Up</button>
                            </form>
                           
                            <div className="login">
                                <p className="newhere">Already Registered?</p>
                                <Link to="/login"><p>Login!</p></Link>
                            </div>
                         
                        </div>
                    </div>
                </div>
               
            </div>
        </div>
    );
}

export default SignUp;
