import React, {useEffect} from 'react';
import ToggleNav from '../components/ToggleNav';
import "../scss/_student_ambassador.scss"
import AOS from "aos";
import ComingSoon from "../components/ComingSoon";

function StudentAmbassadorRegistration() {

    const isActive = true;

    useEffect(() => {
        AOS.init();
        AOS.refresh();
    });

    if (!isActive) {
        return <ComingSoon title={'Events'}/>
    }

    const formStyle = {
        width: "100%",
        height: '100vh',
        borderRadius: '10px',
    }

    return (
        <div className="event-page">
            <div id="back"></div>
            <div>
                {/* <BackgroundStars /> */}
                <div id="stars"></div>
                <div id="stars2"></div>
                <div id="stars3"></div>

                <ToggleNav/>
                <div className="event-page-inner">
                    <div className="page-heading">
                        <h1>STUDENT AMBASSADOR</h1>
                    </div>
                    <div className="event-info">
                        <h2 data-aos="fade-right" data-aos-duration="700">REGISTER</h2>
                        <br/>
                        <iframe style={formStyle} title="form" src="https://docs.google.com/forms/d/e/1FAIpQLScmd0vxvgIuaXLsoFV1w3YVsTbMJdM_OF86vGgN_r57f9-s-w/viewform"></iframe>
                    </div>

                </div>

            </div>
        </div>

    );
}
export default StudentAmbassadorRegistration;
