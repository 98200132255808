import React from 'react';


function BackgroundCircle(){
    return(


<div className="background-circle">
<svg id="background-circle" width="193" height="193" viewBox="0 0 193 193" fill="none" xmlns="http://www.w3.org/2000/svg">
<path id="innermost"  d="M96.49 31.02C60.3 31.02 30.96 60.36 30.96 96.55C30.96 132.74 60.3 162.08 96.49 162.08C132.68 162.08 162.02 132.74 162.02 96.55C162.02 60.36 132.68 31.02 96.49 31.02ZM96.49 160.06C61.41 160.06 32.98 131.63 32.98 96.55C32.98 61.47 61.41 33.04 96.49 33.04C131.57 33.04 160 61.47 160 96.55C160 131.63 131.57 160.06 96.49 160.06Z" fill="#083657"/>
<path id="inner" d="M96.49 184.98C145.33 184.98 184.92 145.39 184.92 96.55C184.92 47.71 145.33 8.12 96.49 8.12C55.35 8.12 20.77 36.21 10.9 74.26C9.05001 81.38 8.07001 88.85 8.07001 96.54C8.06001 145.39 47.65 184.98 96.49 184.98Z" stroke="#138CBC" strokeWidth="0.75" strokeMiterlimit="10"/>
<path className="rotation" id="outer-rotor"d="M96.49 190.85C148.57 190.85 190.79 148.63 190.79 96.55C190.79 44.47 148.57 2.25 96.49 2.25C52.62 2.25 15.75 32.21 5.21 72.78C3.24 80.37 2.19 88.34 2.19 96.54C2.19 148.63 44.41 190.85 96.49 190.85Z" stroke="#D8D8D8" strokeWidth="3" strokeMiterlimit="10" strokeDasharray="1.25 4.25"/>

<g id="inner-rotor" className="rotation">
<path d="M85.39 165.51C75.6 163.95 66.5 160.34 58.52 155.16L52.53 164.7C61.78 170.68 72.3 174.83 83.62 176.64L85.39 165.51Z" fill="#ffffff"/>
<path d="M31.27 121.54C29.94 118.06 28.86 114.45 28.09 110.73L17.02 112.83C17.96 117.42 19.3 121.86 20.98 126.14L31.27 121.54Z" fill="#ffffff"/>
<path d="M91.92 15.59C80.16 16.24 69.07 19.4 59.17 24.54L64.08 34.69C72.55 30.25 82.04 27.49 92.11 26.87L91.92 15.59Z" fill="#ffffff"/>
<path d="M15.38 96.55C15.38 100.94 15.74 105.24 16.41 109.44L27.54 107.7C26.96 104.07 26.64 100.35 26.64 96.55C26.64 90.47 27.42 84.57 28.88 78.95C28.93 78.75 29 78.55 29.05 78.35L18.11 75.62C18.07 75.79 18.01 75.95 17.97 76.12C16.29 82.64 15.38 89.49 15.38 96.55Z" fill="#ffffff"/>
<path d="M45.03 33.88L52.16 42.6C55.06 40.22 58.15 38.07 61.4 36.17L56.45 26.02C52.42 28.31 48.6 30.94 45.03 33.88Z" fill="#ffffff"/>
<path d="M161.58 71.24L172.36 67.88C168.29 57.11 162 47.44 154.04 39.42L145.84 47.15C152.64 53.93 158.03 62.12 161.58 71.24Z" fill="#ffffff"/>
<path d="M140.37 28.36L134.38 37.9C137.57 39.96 140.59 42.26 143.39 44.81L151.06 36.55C147.74 33.53 144.16 30.8 140.37 28.36Z" fill="#ffffff"/>
<path d="M159 127.7C154.62 136.48 148.46 144.21 140.97 150.4L148.05 159.16C156.6 152.11 163.67 143.32 168.75 133.36L159 127.7Z" fill="#ffffff"/>
<path d="M100.3 166.29C99.04 166.36 97.77 166.4 96.49 166.4C93.8 166.4 91.16 166.23 88.55 165.94L87.57 177.17C90.5 177.49 93.48 177.67 96.49 177.67C97.77 177.67 99.04 177.63 100.3 177.57V166.29Z" fill="#ffffff"/>
<path d="M174.81 117.66L163.94 114.69C162.98 118.25 161.75 121.7 160.27 125.01L170.24 130.3C172.1 126.24 173.63 122.02 174.81 117.66Z" fill="#ffffff"/>
</g>

</svg>
            {/* <svg id="background-circle"  viewBox="0 0 240 241" fill="none" xmlns="http://www.w3.org/2000/svg">  
                <path  d="M118.49 56.02C82.3 56.02 52.96 85.36 52.96 121.55C52.96 157.74 82.3 187.08 118.49 187.08C154.68 187.08 184.02 157.74 184.02 121.55C184.02 85.36 154.68 56.02 118.49 56.02ZM118.49 185.06C83.41 185.06 54.98 156.63 54.98 121.55C54.98 86.47 83.41 58.04 118.49 58.04C153.57 58.04 182 86.47 182 121.55C182 156.63 153.57 185.06 118.49 185.06Z" fill="#083657"/>
                <path  d="M118.49 209.98C167.33 209.98 206.92 170.39 206.92 121.55C206.92 72.71 167.33 33.12 118.49 33.12C77.35 33.12 42.77 61.21 32.9 99.26C31.05 106.38 30.07 113.85 30.07 121.54C30.06 170.39 69.65 209.98 118.49 209.98Z" stroke="#138CBC" strokeWidth="0.75" strokeMiterlimit="10"/>
                <path className="rotation" id="outer-rotor" d="M118.49 215.85C170.57 215.85 212.79 173.63 212.79 121.55C212.79 69.47 170.57 27.25 118.49 27.25C74.62 27.25 37.75 57.21 27.21 97.78C25.24 105.37 24.19 113.34 24.19 121.54C24.19 173.63 66.41 215.85 118.49 215.85Z" stroke="#D8D8D8" strokeWidth="3" strokeMiterlimit="10" stroke-dasharray="1.25 4.25"/>
                <g id="inner-rotor" className="rotation">
                    <path d="M107.39 190.51C97.6 188.95 88.5 185.34 80.52 180.16L74.53 189.7C83.78 195.68 94.3 199.83 105.62 201.64L107.39 190.51Z" fill="#ffffff"/>
                    <path d="M53.27 146.54C51.94 143.06 50.86 139.45 50.09 135.73L39.02 137.83C39.96 142.42 41.3 146.86 42.98 151.14L53.27 146.54Z" fill="#ffffff"/>
                    <path d="M113.92 40.59C102.16 41.24 91.07 44.4 81.17 49.54L86.08 59.69C94.55 55.25 104.04 52.49 114.11 51.87L113.92 40.59Z" fill="#ffffff"/>
                    <path d="M37.38 121.55C37.38 125.94 37.74 130.24 38.41 134.44L49.54 132.7C48.96 129.07 48.64 125.35 48.64 121.55C48.64 115.47 49.42 109.57 50.88 103.95C50.93 103.75 51 103.55 51.05 103.35L40.11 100.62C40.07 100.79 40.01 100.95 39.97 101.12C38.29 107.64 37.38 114.49 37.38 121.55Z" fill="#ffffff"/>
                    <path d="M67.03 58.88L74.16 67.6C77.06 65.22 80.15 63.07 83.4 61.17L78.45 51.02C74.42 53.31 70.6 55.94 67.03 58.88Z" fill="#ffffff"/>
                    <path d="M183.58 96.24L194.36 92.88C190.29 82.11 184 72.44 176.04 64.42L167.84 72.15C174.64 78.93 180.03 87.12 183.58 96.24Z" fill="#ffffff"/>
                    <path d="M162.37 53.36L156.38 62.9C159.57 64.96 162.59 67.26 165.39 69.81L173.06 61.55C169.74 58.53 166.16 55.8 162.37 53.36Z" fill="#ffffff"/>
                    <path d="M181 152.7C176.62 161.48 170.46 169.21 162.97 175.4L170.05 184.16C178.6 177.11 185.67 168.32 190.75 158.36L181 152.7Z" fill="#ffffff"/>
                    <path d="M122.3 191.29C121.04 191.36 119.77 191.4 118.49 191.4C115.8 191.4 113.16 191.23 110.55 190.94L109.57 202.17C112.5 202.49 115.48 202.67 118.49 202.67C119.77 202.67 121.04 202.63 122.3 202.57V191.29Z" fill="#ffffff"/>
                    <path d="M196.81 142.66L185.94 139.69C184.98 143.25 183.75 146.7 182.27 150.01L192.24 155.3C194.1 151.24 195.63 147.02 196.81 142.66Z" fill="#ffffff"/>
                </g>
            </svg> */}
            
            <h1>National Students'<br />Space Challenge</h1>       
        </div>
    );
}

export default BackgroundCircle;