import React from 'react';
import WorkshopTile from './WorkshopTile.js';

import "../../scss/_workshop_menu.scss"
import guests from '../../data/guests.json';
import TempBack from "../Backgrounds/Shuttle";
import ComingSoon from "../ComingSoon";


function GuestMenu(props) {

    return (
        <div>
            <div className="page-heading">
            <div className="space">
                    <div className="space-container">

                        <div className="planet mine"></div>
                        {/* <h1>NSSC EVENTS</h1> */}
                    </div>
                </div>
            <h1 className="event-title">GUEST LECTURES</h1>
            </div>
            {
                props.isActive && guests.map((workshop, index) => <WorkshopTile key={index} workshopName={workshop.workshopName}
                                                                          workshopImg={workshop.workshopImg}
                                                                          workshopDate={workshop.workshopDate}
                                                                          workshopDesc={workshop.workshopDesc}
                                                                          workshopLink={workshop.workshopLink}/>)
            }

        </div>
    );
}

export default GuestMenu;