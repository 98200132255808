import React from 'react'
import LandingHeader from '../LandingHeader';

function TopForeground(){
    return(
        <div className="background-outline-top">  
            {/* <img className="background-foreground-img" src={foreground} alt="NSSC Foreground"></img> */}
            <div className="background-foreground-top">
                <LandingHeader />
            </div>
        </div>
    );
}

export default TopForeground;
