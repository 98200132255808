import React, {useEffect} from 'react';
import BackgroundStars from '../../components/shared/Backgrounds/BackgroundStars';
import TopForeground from '../../components/shared/Foregrounds/TopForeground';
import BottomForeground from '../../components/shared/Foregrounds/BottomForeground';
import LandingFront from '../../components/shared/LandingFront';
import ComingSoon from "../../components/ComingSoon";
import shipToggle from "../../util/ship_toggle";



function LandingPage(props){

    // useEffect(() => {
    //     shipToggle(false);
    // },[])

    return (
        <div>
            <div className="default-background-image landing-background" >
            {/*<img className="comet" src="/imgs/logo/comet.svg" alt="" />*/}
            {/* <img className="ship" src="/imgs/logo/ship.svg" alt="" /> */}
            {/*<BackgroundStars />*/}
            <div className="blackTransparent"></div>
            {/* <div className="default-background-image landing-background"></div> */}
            
            
            {/* <BackgroundImage /> */}
            <TopForeground />
            {/* <BackgroundCircle /> */}
            {/* <NsscTitle /> */}
            {/* <BackgroundForeground /> */}

            <LandingFront click={props.click} condition={props.condition} />
            <BottomForeground />
            {/* <LandingHeader /> */}
        </div>
        </div>
    );
}

export default LandingPage;
