import React from 'react';
import {Link} from 'react-router-dom';
import ToggleNav from '../ToggleNav';
import ComingSoon from "../ComingSoon";
import {API_URL} from '../../constants';

const handleSubmit = (e) => {
    e.preventDefault();
    const { email, password } = e.target;
    const data = {
        email: email.value,
        password: password.value,
    }

    fetch(`${API_URL}/api/login`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
    })
    .then(async res => {
        let data= await res.json();
        if(res.status === 200) {
            console.log(data);
            localStorage.setItem('jwt', data.token);
            window.location.href = '/dashboard';
        }
        else {
            alert(data.errors[0].msg);
        }
    }, err => {
        console.log(err);
    });
}

function Login() {

    console.log("login");
    return (
        <div className="login-page-container">
            {/*<img className="cloud2" src="/imgs/login/cloud2.svg" alt=""/>*/}
            {/*<img className="cloud3" src="/imgs/login/cloud3.svg" alt=""/>*/}
            <Link to="/">
                <button className="back">
                    <i className="fa fa-arrow-left"></i>
                </button>
            </Link>
            <ToggleNav/>
            <div className="login-page">
                <div className="login-detail">
                    <img src="/imgs/login/clouds.svg" alt=""/>
                    <div className="login">
                        <h1>NSSC 2021</h1>
                        <div className="login-info">
                            <h2>
                                Log in
                            </h2>
                            <form onSubmit={handleSubmit}>
                                <div className="input">
                                    <i className="fa fa-envelope"></i>
                                    <input type="email" name="email" id="" placeholder="Email address"/>
                                </div>
                                <div className="input">
                                    <i className="fa fa-lock"></i>
                                    <input type="password" name="password" id="" placeholder="Password"/>
                                </div>
                                <button type="submit">Login</button>
                            </form>
                            <div className="new">
                                {/* <div className="remember-me">
                            <input type="radio" name="" id="" />
                            <p>Remember me</p>
                        </div> */}
                                <div className="signin">
                                    <p className="newhere">New Here?</p>
                                    <Link to="/signup"><p>Sign up!</p></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="login-img">
                    <div className="login-logo">
                        <h1>"Exoplanets"</h1>
                        <img src="/imgs/login/loginimage.svg" alt=""/>
                        <h2>Worlds Of Possibilities</h2>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Login;
