import React from 'react'

function SponsSection(props){
    return(
        <div className="spons-section">
            <h3>
                {props.heading}
            </h3>
            <div className="spons-imgs">
               {props.imgs.map((img,i) => 
                <div key={i} className="spons-img">
                    <a href={img.link}><img  src={`/imgs/sponsors/${props.year+"/"+img.img}`} alt="img" /></a>
                </div>
               )}
            </div>
        </div>
    );
}

export default SponsSection;