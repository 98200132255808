import React from 'react';
import {Link} from 'react-router-dom';
import Workshop from '../../../components/Workshop/DetailPage/Workshop';
import ToggleNav from '../../../components/ToggleNav';
function WorkshopPage(props){
    return(
        <div className="event-page">

            <div id="stars"></div>
            <div id="stars2"></div>
            <div id="stars3"></div> 

            <ToggleNav />

            <Link to="/workshops">
   <button className="back">
        <i className="fa fa-arrow-left"></i>
    </button>
   </Link> 

            <Workshop workshop={props.workshop}/>
        </div>
    );
}

export default WorkshopPage;
