import React from 'react'
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import InstagramIcon from '@material-ui/icons/Instagram';
import FacebookIcon from '@material-ui/icons/Facebook';

function BottomForeground(){
    return(
        <div className="background-outline-bottom">  
            <div className="background-foreground-bottom">
                     {/* <img className="background-foreground" src={foreground} alt="NSSC Foreground"></img> */}
                     <ul className="social-media-icons">
                         <li>
                             <a href="https://www.linkedin.com/company/national-students'-space-challenge/" id="linkedin" target='_blank'>
                                <LinkedInIcon className="linkedin" />
                             </a>
                         </li>
                         <li>
                             <a href="https://www.instagram.com/spats.iitkgp/" id="insta" target='_blank'>
                                <InstagramIcon className="insta" />
                             </a>
                         </li>
                         <li>
                             <a href="https://www.facebook.com/nssc.iit/" id="fb" target='_blank'>
                                <FacebookIcon className="fb"/>
                             </a>
                         </li>
                     </ul>
            </div> 
        </div>
    );
}

export default BottomForeground;            
