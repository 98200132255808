import React from 'react';
import Tilt from 'react-tilt';
import "./style.scss";
import members from '../../data/team.json';
import subhead from '../../data/subhead.json';
// import * as THREE from "three";
// import script from '../team';


// const fs = require('fs');
// let M;
// let mem;
// M=fs.readFileSync('../../data/team.json');
// mem=JSON.parse(M);
// function change()
// {
    
//     M=fs.readFileSync('../../data/team.json');
//     mem=JSON.parse(M);
// }



function TeamMenu(props) {


    function click(el)
    {
        el.preventDefault();
        
    
        document.querySelectorAll(".a1").forEach(a=>a.style.display = "none");
        document.querySelectorAll(".a2").forEach(a=>a.style.display = "flex");
        document.querySelectorAll(".button1").forEach(a=>a.classList.remove('active'));
        document.querySelectorAll(".button2").forEach(a=>a.classList.add('active'));


    }
    function click2(el)
    {
        el.preventDefault();
        
    
        document.querySelectorAll(".a2").forEach(a=>a.style.display = "none");
        document.querySelectorAll(".a1").forEach(a=>a.style.display = "flex");
        document.querySelectorAll(".button2").forEach(a=>a.classList.remove('active'));
        document.querySelectorAll(".button1").forEach(a=>a.classList.add('active'));


    }

    return (
        <div className="team-page-inner">

            <h1>team</h1>
            <form onSubmit={click2}>
                <button type='submit' class="button active button1">Head</button>
            </form>
            <form onSubmit={click}>
               <button type='submit' class="button button2">Subhead</button>
            </form>
            <div className="team_container a1">

                {members.map((member, i) =>
                    <Tilt className="team_card" options={{max: 25}}>

                            <div className="team_image"><img
                                src={`/imgs/team/${member.img}`}
                                alt=""/></div>
                            <div className="team_info">
                                <h1 id="personName">{member.name}</h1>
                                <p>{member.design}</p>
                                <div className="team_social">
                                    <a href={member.link.facebook} className="fa fa-facebook socialIcon"></a>
                                    <a href={member.link.linkedin}  className="fa fa-linkedin socialIcon"></a>
                                </div>

                            </div>
                    </Tilt>
                )}
            </div>
            <div className="team_container a2">

               {subhead.map((member, i) =>
                   <Tilt className="team_card" options={{max: 25}}>

                           <div className="team_image"><img
                               src={`/imgs/team/${member.img}`}
                               alt=""/></div>
                           <div className="team_info">
                               <h1 id="personName">{member.name}</h1>
                               <p>{member.design}</p>
                               <div className="team_social">
                                   <a href={member.link.facebook} className="fa fa-facebook socialIcon"></a>
                                   <a href={member.link.linkedin}  className="fa fa-linkedin socialIcon"></a>
                               </div>

                           </div>
                   </Tilt>
               )}
            </div>
            {/* <div className="team-inner">
                <div className="team-menu">
                {members.map((member,i) => <TeamMember 
                    key={i}  
                    name={member.name}
                    img={member.img}
                    design={member.design}
                    phone={member.phone}
                    email={member.email}
                    link={member.link}
                    />)}
                </div>
            </div> */}

        </div>
    );
}


export default TeamMenu;
