import React from "react";

const navLogo = <svg id="nav-logo" viewBox="0 0 497 250" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M2.42999 227.64H147.19L158.94 246.53H474" stroke="#452F90" strokeWidth="3" strokeMiterlimit="10" strokeLinecap="round"/>
    <path d="M474 248.06L494.94 207.89" stroke="#452F90" strokeWidth="3" strokeMiterlimit="10" strokeLinecap="round"/>
    <path d="M493.15 20.83H351.45L344.04 8.57001" stroke="#452F90" strokeWidth="3" strokeMiterlimit="10"/>
    <path d="M349.91 50.96V50.07L340.13 29.77H23.51L2.42999 76.02V200.57H147.19L158.3 222.02H475.02H475.71L494.94 180.04V50.96H349.91Z" fill="#452F90"/>
    <path d="M47.87 0.0599976H33.15L21.83 12.32H37.32L47.87 0.0599976Z" fill="#452F90"/>
    <path d="M58.28 12.32L68.83 0.0599976H54.11L42.79 12.32H58.28Z" fill="#452F90"/>
    <path d="M79.43 12.32L89.98 0.0599976H75.26L63.94 12.32H79.43Z" fill="#452F90"/>
    <path d="M101.02 12.32L111.57 0.0599976H96.85L85.53 12.32H101.02Z" fill="#452F90"/>
    <path d="M122.21 12.32L132.77 0.0599976H118.04L106.72 12.32H122.21Z" fill="#452F90"/>
    <path d="M143.19 12.32L153.74 0.0599976H139.02L127.7 12.32H143.19Z" fill="#452F90"/>
    <path d="M163.62 12.32L174.17 0.0599976H159.45L148.13 12.32H163.62Z" fill="#452F90"/>
    <path d="M185.32 12.32L195.87 0.0599976H181.15L169.83 12.32H185.32Z" fill="#452F90"/>
    <path d="M206.51 12.32L217.06 0.0599976H202.34L191.02 12.32H206.51Z" fill="#452F90"/>
    <path d="M226.43 12.32L236.98 0.0599976H222.26L210.94 12.32H226.43Z" fill="#452F90"/>
    <path d="M248.13 12.32L258.68 0.0599976H243.96L232.64 12.32H248.13Z" fill="#452F90"/>
    <path d="M269.06 12.32L279.62 0.0599976H264.89L253.57 12.32H269.06Z" fill="#452F90"/>
    <path d="M290 12.32L300.55 0.0599976H285.83L274.51 12.32H290Z" fill="#452F90"/>
    <path d="M311.19 12.32L321.74 0.0599976H307.02L295.7 12.32H311.19Z" fill="#452F90"/>
    <path d="M332.13 12.32L342.68 0.0599976H327.96L316.64 12.32H332.13Z" fill="#452F90"/>
</svg>;
    export default navLogo;
