import React, {useEffect} from 'react';
import initAnimation from "./script";

function TempBack(props:{xposition:boolean}){
    // useEffect(()=>{
    //     initAnimation(props.xposition);
    // },[]);

    return (<div></div>);
}

export default TempBack;
