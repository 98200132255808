import React,{useEffect} from 'react';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
// import PhoneAndroidIcon from '@material-ui/icons/PhoneAndroid';
import HeadsetMicIcon from '@material-ui/icons/HeadsetMic';
import AOS from "aos";
import "aos/dist/aos.css";
import Linkify from 'linkify-react';

function Workshop(props){

    useEffect(() => {
        AOS.init();
        AOS.refresh();
    });

    return(
        <div className="event-page-inner">
            {props.workshop.map((workshop)=>
             {
            return (<div>
             <div className="page-heading">
                <div className="space">
                    <div className="space-container">
                        
                        <div className="planet mine"></div>
                        {/* <h1>NSSC EVENTS</h1> */}
                    </div>
                </div>
                <h1 >{workshop.workshopName}</h1>
            </div>
            <div className="event-info">
                <h2 data-aos="fade-right" data-aos-duration="700">INTRODUCTION</h2>
                <p data-aos="fade-left" data-aos-duration="700">{workshop.intro}</p>
            </div>
            <div className="event-info">
            <h2 data-aos="fade-right" data-aos-duration="700">SPEAKER</h2>
                <div className="contact-detail">
                        <p data-aos="fade-left" data-aos-duration="700">{workshop.speaker}</p>
                </div>
            </div>
            {
                workshop?.registrationLink &&
                <div className="event-info">
                <h2 data-aos="fade-right" data-aos-duration="700">Registration Link</h2>
                    <div className="contact-detail">
                            <Linkify><p data-aos="fade-left" data-aos-duration="700">{workshop.registrationLink}</p></Linkify>
                    </div>
                </div>
            }
            <div className="event-info">
            <h2 data-aos="fade-right" data-aos-duration="700">TIMINGS</h2>
                <div className="contact-detail">
                        <p data-aos="fade-left" data-aos-duration="700">Date: {workshop.date}</p>
                        <p data-aos="fade-left" data-aos-duration="700">Time: {workshop.time}</p>
                </div>
            </div>
            </div>);
        })}
        </div>
    );
}

export default Workshop;
