import React, {useEffect} from 'react';
import {Link} from 'react-router-dom';
import TeamMenu from '../../components/Team/TeamMenu';
import ToggleNav from '../../components/ToggleNav';
import shipToggle from "../../util/ship_toggle";
// import '../scss/team_page.scss';

function TeamPage(){

    // useEffect(() => {
    //     shipToggle(false);
    // },[])

    return(
        <div className="team-page">
            {/*<div id="stars"></div>*/}
            {/*<div id="stars2"></div>*/}
            {/*<div id="stars3"></div>  */}

            <Link to="/">
            <button className="back">
                    <i className="fa fa-arrow-left"></i>
                </button>
            </Link>

            {/* <div className="team-page-heading">
                <div className="space">
                    <div className="space-container">
                        
                        <div className="planet mine"></div>
                      
                    </div>
                </div>
            </div> */}

            <ToggleNav />

            <TeamMenu />
        </div>
    );
}

export default TeamPage;
