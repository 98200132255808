import React from 'react';
import {Link} from 'react-router-dom';
// import contact_alien from '../imgs/svgs/contact_alien.svg';
import logo from '../../imgs/nssc_icon.png';
// import contact_meter from '../imgs/svgs/contact_meter.svg';

function LandingHeader(){
    return(
        <div className="landing-header">
            <div className="nssc-logo-container">
                <div className="nssc-logo">
                    <Link to="/">
                        <img className="nssc-logo-img" src={logo} alt="Alien Contact Border" />        
                    </Link>
                </div> 

                <svg id="nssc-logo"  viewBox="0 0 268 88" fill="none" xmlns="http://www.w3.org/2000/svg">            
                    <g id="outer-rotor" className="rotation">
                        <path  d="M48.57 12.7C62.25 14.87 73.05 25.68 75.23 39.36H77.04C74.84 24.69 63.24 13.09 48.57 10.89V12.7V12.7Z" fill="#E2E2E2" stroke="#2EC6B0" strokeWidth="0.55" strokeMiterlimit="10"/>
                        <path  d="M11.74 39.36C13.92 25.65 24.76 14.83 38.48 12.69V10.88C23.77 13.05 12.13 24.66 9.91998 39.36H11.74Z" fill="#E2E2E2" stroke="#2EC6B0" strokeWidth="0.55" strokeMiterlimit="10"/>
                        <path  d="M38.49 76.21C24.74 74.06 13.88 63.2 11.73 49.45H9.91998C12.1 64.19 23.75 75.84 38.49 78.02V76.21Z" fill="#E2E2E2" stroke="#2EC6B0" strokeWidth="0.55" strokeMiterlimit="10"/>
                        <path  d="M75.25 49.45C73.11 63.17 62.29 74.02 48.58 76.19V78C63.28 75.79 74.89 64.16 77.06 49.44H75.25V49.45Z" fill="#E2E2E2" stroke="#2EC6B0" strokeWidth="0.55" strokeMiterlimit="10"/>
                    </g>
                    <g id="inner-rotor" className="rotation">
                        <path  d="M67.71 43.83C67.72 44.04 67.73 44.24 67.73 44.45C67.73 57.83 56.88 68.68 43.5 68.68C30.12 68.68 19.26 57.83 19.26 44.45C19.26 31.07 30.11 20.22 43.49 20.22C43.65 20.22 43.82 20.23 43.98 20.23V17.93C43.82 17.93 43.65 17.92 43.49 17.92C28.84 17.92 16.96 29.8 16.96 44.45C16.96 59.1 28.84 70.98 43.49 70.98C58.14 70.98 70.02 59.1 70.02 44.45C70.02 44.24 70.01 44.04 70 43.83H67.71V43.83Z" fill="grey"/>
                        <path  d="M45.77 16.94V21.13C56.8 22.19 65.6 30.91 66.78 41.91H70.98C69.77 28.6 59.11 18.03 45.77 16.94Z" fill="#2EC6B0"/>
                    </g>
                    <g id="bottom-part">
                        <path  d="M74.02 67.87H155.55L159.64 63.72H77.43L74.02 67.87Z" fill="#E5E5E5" stroke="#00D2CA" strokeMiterlimit="10"/>
                        <path  d="M79.47 60.04H113" stroke="#00D2CA" strokeMiterlimit="10"/>
                        <path  d="M163.89 63.73H215.81" stroke="#00D2CA" strokeMiterlimit="10"/>
                        <path  d="M215.81 64.81C216.406 64.81 216.89 64.3265 216.89 63.73C216.89 63.1335 216.406 62.65 215.81 62.65C215.214 62.65 214.73 63.1335 214.73 63.73C214.73 64.3265 215.214 64.81 215.81 64.81Z" fill="#00D2CA"/>
                    </g>
                    <g id="top-part">
                        <path d="M77.43 24.55H95.81L98.7 22.51H120.06L122.62 24.55H265.85" stroke="#3FB0B0" strokeWidth="1.5" strokeMiterlimit="10"/>
                        <path d="M265.85 26.59C266.977 26.59 267.89 25.6767 267.89 24.55C267.89 23.4233 266.977 22.51 265.85 22.51C264.723 22.51 263.81 23.4233 263.81 24.55C263.81 25.6767 264.723 26.59 265.85 26.59Z" fill="#00D2CA"/>
                        <path d="M73.21 18.68H93.76C93.76 18.68 96.19 20.47 93.76 22.51H75.51C75.51 22.51 74.79 19.96 73.21 18.68Z" fill="#E8E8E8"/>
                    </g>
                </svg>

                
            </div>
                       
        </div>
    );
}

export default LandingHeader;
