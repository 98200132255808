import React, {useEffect} from 'react';
import {Link} from 'react-router-dom';
// import BackgroundStars from '../components/BackgroundStars';
import EventMenu from '../../components/Event/EventMenu';
import ToggleNav from '../../components/ToggleNav';
import TempBack from "../../components/Backgrounds/Shuttle";
import ComingSoon from "../../components/ComingSoon";
import shipToggle from "../../util/ship_toggle";
import BackgroundStars from "../../components/shared/Backgrounds/BackgroundStars";
// import '../scss/background_stars.scss';
// import '../scss/event_menu.scss';

// const mystyle = {
//     position: "absolute",
//     top: "0",
//     left: "0",
//     height: "100%",
//     width: "100%",
//     backgroundColor: "red",
//     opacity: "0.5"
// }

function Events() {

    // useEffect(() => {
    //     shipToggle(true);
    // },[])

    const isActive = true;

    if(!isActive){
        return <ComingSoon title={'Events'}/>
    }
    return (
        <div className="event-menu" >
            <div id="back"></div>
            <div>
                {/* <BackgroundStars /> */}
                <div id='stars'></div>
                <div id='stars2'></div>
                <div id='stars3'></div>

                {/*<ComingSoon/>*/}


                {/* <svg id="orbits" height="400" width="400">
                <circle cx="200" cy="200" r="200" stroke="white" stroke-width="2" fill="none" />
            </svg> */}


                <ToggleNav/>

                <Link to="/">
                    <button className="back">
                        <i className="fa fa-arrow-left"></i>
                    </button>
                </Link>



                <div className="event-menu-inner" style={{minHeight: '100vh'}}>
                    <EventMenu isActive={isActive}/>
                </div>

            </div>
        </div>

    );
}

export default Events;
