import React,{useState} from 'react';
import {Link} from 'react-router-dom';
import MenuIcon from '@material-ui/icons/Menu';

function ToggleNav(){
    
    const navs = [
        "events",
        "team",
        "gallery",
        "about",
        "sponsors",
        "workshops",
        "contact",
        "login",
        "guests"
    ];
    // const menu = document.getElementsByClassName("toggler");

    const [isMenu, setMenu] = useState(false);


    function handleMenu(){
        setMenu(!isMenu);
        console.log('togglenav',isMenu);
        // if(isMenu){
        //     for(var i = 0; i< menu.length ; i++){
        //         menu[i].style.display = "block";
        //     }
        // }
    }

    return(
        <div className="toggle-nav" style={{zIndex:2000}}>
            <div className="inner-toggle">
                <button className="menu-icon"  onClick={handleMenu}>
                    <MenuIcon  />
                </button>
               {isMenu&& <div className="toggler-menu">
                    {navs.map((nav,i) => {


                        return <div className="toggler" key={i}>
                        <Link to={"/"+nav} className="toggler-nav" onClick={handleMenu}>
                            {nav}
                        </Link>
                        </div>;
                    })}
                </div>}
                <div className="toggler-menu-2">
                    {navs.map((nav,i) => {


                        return <div className="toggler" key={i}>
                        <Link to={"/"+nav} className="toggler-nav" onClick={handleMenu}>
                            {nav}
                        </Link>
                        </div>;
                    })}
                </div>
            </div>
        </div>
    );
}

export default ToggleNav;
