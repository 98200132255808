import React from 'react';
import { Link } from 'react-router-dom';
import comet from '../../imgs/icons/icon_comet.png';
import telescope from '../../imgs/icons/icon_telescope.png';
import satellite from '../../imgs/icons/icon_satellite.png';
import antena from '../../imgs/icons/icon_antena.png';
import ufo from '../../imgs/icons/icon_ufo.png';
import alienship from '../../imgs/icons/icon_alienship.png';
import BackgroundCircle from './Backgrounds/BackgroundCircle';
import navLogo from './navLogo';

function LandingFront(props) {

    // const [props.condition,setClicked] = useState(false);

    const loginStyle={
        top: '35%',
    }

    return (
        <div className="landing-navigation">
            <div className="landing-navigation-inner">
                <div className="left-main main">

                    {props.condition && <button onClick={() => {
                        props.click();
                    }} className="back" style={{marginTop:"8vh"}}>
                        <i className="fa fa-arrow-left"></i>
                    </button>}

                    {props.condition && <Link to="/events" className="left-nav nav left">

                        <img className="nav-icon" src={comet} alt="Comet" />
                        <div className="nav-logo-container">
                            {navLogo}
                            <h1>
                                Events
                            </h1>
                        </div>


                    </Link>}


                    {props.condition && <Link to="/team" className="left-nav nav right">

                        <img className="nav-icon pc" src={telescope} alt="telescope" />
                        <div className="nav-logo-container">
                            {navLogo}
                            <h1>
                                Team
                            </h1>
                        </div>
                        <img className="nav-icon mobile" src={telescope} alt="t}elescope" />

                    </Link>}


                    {props.condition && <Link to="/gallery" className="left-nav nav left">
                        <img className="nav-icon" src={satellite} alt="satellite" />
                        <div className="nav-logo-container">
                            {navLogo}
                            <h1>
                                Gallery
                            </h1>
                        </div>
                    </Link>}


                    {props.condition && <Link to="/about" className="left-nav nav right">
                        <img className="nav-icon pc" src={satellite} alt="satellite" />
                        <div className="nav-logo-container">
                            {navLogo}
                            <h1>
                                About
                            </h1>
                        </div>
                        <img className="nav-icon mobile" src={satellite} alt="satellite" />
                    </Link>}


                    {props.condition && <Link to="/guests" className="left-nav nav right">

                        <img className="nav-icon mobile" src={antena} alt="Comet" />
                        <div className="nav-logo-container">
                            {navLogo}
                            <h1>
                                Guest Lectures
                            </h1>
                        </div>
                        <img className="nav-icon pc" src={antena} alt="Comet" />


                    </Link>}


                </div>




                {props.condition && <BackgroundCircle />}

                <div className="right-main main">

                    {props.condition && <Link to="/sponsors" className="right-nav nav left">

                        <img className="nav-icon mobile" src={antena} alt="Comet" />
                        <div className="nav-logo-container">
                            {navLogo}
                            <h1>
                                Sponsors
                            </h1>
                        </div>
                        <img className="nav-icon pc" src={antena} alt="Comet" />


                    </Link>}

                    {props.condition && <Link to="/workshops" className="right-nav nav left">

                        <img className="nav-icon mobile" src={antena} alt="Comet" />
                        <div className="nav-logo-container">
                            {navLogo}
                            <h1>
                                Workshops
                            </h1>
                        </div>
                        <img className="nav-icon pc" src={antena} alt="Comet" />


                    </Link>}


                    {props.condition && <Link to="/contact" className="right-nav nav right">


                        <div className="nav-logo-container">
                            {navLogo}
                            <h1>
                                Contact
                            </h1>
                        </div>
                        <img className="nav-icon" src={alienship} alt="telescope" />
                    </Link>}

                    {/* </div> */}
                    {/* <div className="nav-row inner-row"> */}
                    {props.condition && <Link to="/login" className="right-nav nav left">
                        <img className="nav-icon mobile" src={ufo} alt="satellite" />
                        <div className="nav-logo-container">
                            {navLogo}
                            <h1>
                                Login
                            </h1>
                        </div>
                        <img className="nav-icon pc" src={ufo} alt="satellite" />
                    </Link>}

                    {props.condition && <Link to="/merchandise" className="right-nav nav left">
                        <img className="nav-icon mobile" src={ufo} alt="satellite" />
                        <div className="nav-logo-container">
                            {navLogo}
                            <h1>
                                Merchandise
                            </h1>
                        </div>
                        <img className="nav-icon pc" src={ufo} alt="satellite" />
                    </Link>}

                    {/* </div> */}
                </div>


                {!props.condition && <div className="landing-scene">

                    <div className="landing-title">

                        <div className="title">
                            <img src="/imgs/logo/title_sponsor.png" alt="" />
                        </div>
                        {/* <div className="logo">
                        <img src="/imgs/logo/icon.png" alt="" />
                    </div> */}
                        <div className="theme">
                            <h1 id="theme">
                                EXOPLANETS
                            </h1>
                        </div>
                        <div className="caption">
                            <h2>
                                "Worlds of Possibilities"
                            </h2>
                        </div>

                        <div>
                            <div className="start" onClick={() => {
                                props.click();
                            }}>
                                <img src="/imgs/logo/explore.svg" alt="" />
                            </div>
                        </div>
                        <div className="login-container">

                        <Link to="/login" className="nav login">

                            <img className="nav-icon mobile" src={antena} alt="Comet" />
                            <div className="nav-logo-container">
                                {navLogo}
                                <h1  style={loginStyle} >
                                    Login
                                </h1>
                            </div>
                            <img className="nav-icon pc" src={antena} alt="Comet" />


                        </Link>
                        <Link to="/signup" className="nav signup">

                            <img className="nav-icon mobile" src={antena} alt="Comet" />
                            <div className="nav-logo-container">
                                {navLogo}
                                <h1  style={loginStyle} >
                                    Register
                                </h1>
                            </div>
                            <img className="nav-icon pc" src={antena} alt="Comet" />


                        </Link>
                        </div>
                    </div>
                </div>}

            </div>
        </div>
    );
}

export default LandingFront;
