import ToggleNav from '../components/ToggleNav';
import "aos/dist/aos.css";
import React, { useEffect } from 'react';
import ComingSoon from "../components/ComingSoon";
import AOS from "aos";
import "../scss/_merchandise.scss";
import { Link } from "react-router-dom";

function Merchandise() {
    const isActive = true;

    useEffect(() => {
        AOS.init();
        AOS.refresh();
    });

    const formStyle = {
        width: "100%",
        height: '100vh',
        borderRadius: '10px',
    }

    if (!isActive) {
        return <ComingSoon title={'Events'} />
    }
    return (
        <div className="event-page">
            <div id="back"></div>
            <div>
                {/* <BackgroundStars /> */}
                <div id="stars"></div>
                <div id="stars2"></div>
                <div id="stars3"></div>

                <ToggleNav />

                <Link to="/">
                    <button className="back">
                        <i className="fa fa-arrow-left"></i>
                    </button>
                </Link>

                <div className="event-page-inner">

                    <div className="page-heading">
                        <div className="space">
                            <div className="space-container">
                                <a href="http://www.prestogifts.com/" target="_blank" rel="noopener noreferrer"><img src="/imgs/logo/presto.jpg" alt="presto logo" className="logo-img"/></a>
                            </div>
                        </div>
                        <h1 className="merch-title">MERCHANDISE</h1>
                    </div>

                    <h1 className="merch-heading" data-aos="fade-left" ><em>T-Shirt</em></h1>
                    <img id="mobile" data-aos="fade-right" data-aos-duration="900" src={`/imgs/merch/tshirt_front.png`} alt="Avatar" className="merch-img" />
                    <img id="mobile" data-aos="fade-right" data-aos-duration="900" src={`/imgs/merch/tshirt_back.png`} alt="Avatar" className="merch-img" />
                    <br />
                    <h2 data-aos="fade-left" data-aos-duration="700">Price:  Rs. 349</h2>
                    <h1 className="merch-heading" data-aos="fade-left" ><em>Hoodie</em></h1>
                    <img id="mobile" data-aos="fade-right" data-aos-duration="900" src={`/imgs/merch/hoodie_front.png`} alt="Avatar" className="merch-img" />
                    <img id="mobile" data-aos="fade-right" data-aos-duration="900" src={`/imgs/merch/hoodie_back.png`} alt="Avatar" className="merch-img" />
                    <br />
                    <h2 data-aos="fade-left" data-aos-duration="700">Price:  Rs. 649</h2>
                    <h1 className="merch-heading" data-aos="fade-left" ><em>Bottle</em></h1>
                    <img id="mobile" data-aos="fade-right" data-aos-duration="900" src={`/imgs/merch/bottle_front.jpeg`} alt="Avatar" className="merch-img" />
                    <img id="mobile" data-aos="fade-right" data-aos-duration="900" src={`/imgs/merch/bottle_back.jpeg`} alt="Avatar" className="merch-img" />
                    <br />
                    <h2 data-aos="fade-left" data-aos-duration="700">Price:  Rs. 399</h2>
                    <br />
                    <iframe style={formStyle} title="merchandise" id="ts-iframe" src="https://www.townscript.com/v2/widget/national-students-space-challenge-2021-411001/booking" frameborder="0" height="600" width="80%"></iframe><link rel="stylesheet" href="https://www.townscript.com/static/Bookingflow/css/ts-iframe.style.css" />
                </div>
            </div>
        </div>

    );
}

export default Merchandise;
