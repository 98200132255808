import React from 'react';
import {Link} from 'react-router-dom';
import ToggleNav from '../../components/ToggleNav';
// import '../gallery';


function Gallery2(){
    return(
        <div className="gallery1">
             
            {/* <link rel="stylesheet" href="../gallery.css" /> */}
            {/* <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.5.0/css/bootstrap.min.css" integrity="sha384-9aIt2nRpC12Uk9gS9baDl411NQApFmC26EwAOH8WgZl5MYYxFfc+NcPb1dKGj7Sk" crossorigin="anonymous" />
            <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/Swiper/3.4.0/css/swiper.min.css" />
            <link href='https://fonts.googleapis.com/css?family=Lato:300,400,700' rel='stylesheet' type='text/css' /> */}

            
            {/* <script src="https://code.jquery.com/jquery-3.5.1.slim.min.js" integrity="sha384-DfXdz2htPH0lsSSs5nCTpuj/zy4C+OGpamoFVy38MVBnE+IbbVYUew+OrCXaRkfj" crossorigin="anonymous"></script>
            <script src="https://cdn.jsdelivr.net/npm/popper.js@1.16.0/dist/umd/popper.min.js" integrity="sha384-Q6E9RHvbIyZFJoft+2mJbHaEWldlvI9IOYy5n3zV9zzTtmI3UksdQRVvoxMfooAo" crossorigin="anonymous"></script>
            <script src="https://stackpath.bootstrapcdn.com/bootstrap/4.5.0/js/bootstrap.min.js" integrity="sha384-OgVRvuATP1z7JjHLkuOU7Xw704+h835Lr+6QL9UvYjZE3Ipu6Tp75j7Bh/kR0JKI" crossorigin="anonymous"></script>
            <script src="https://cdnjs.cloudflare.com/ajax/libs/Swiper/3.4.0/js/swiper.min.js"></script> */}
            {/* <script src="../gallery.js"></script> */}

            <div id='stars'></div>
          <div id='stars2'></div>
          <div id='stars3'></div>

            <ToggleNav />

         <div className="gallery-inner">
         <div className="row">
                    <div className="col-sm-3"><img src="/imgs/gallery/IMG_2436.JPG" alt="" /></div>
                    <div className="col-sm-3"><img src="/imgs/gallery/IMG_2449.JPG" alt="" /></div>
                    <div className="col-sm-3"><img src="/imgs/gallery/IMG_2454.JPG" alt="" /></div>
                    <div className="col-sm-3"><img src="/imgs/gallery/IMG_2476.JPG" alt="" /></div>
                    </div><div className="row">
                    <div className="col-sm-3"><img src="/imgs/gallery/IMG_2505.JPG" alt="" /></div>
                    <div className="col-sm-3"><img src="/imgs/gallery/IMG_2506.JPG" alt="" /></div>
                    <div className="col-sm-3"><img src="/imgs/gallery/IMG_2510.JPG" alt="" /></div>
                    <div className="col-sm-3"><img src="/imgs/gallery/IMG_2650.JPG" alt="" /></div>
                    </div><div className="row">
                    <div className="col-sm-3"><img src="/imgs/gallery/IMG_2668.JPG" alt="" /></div>
                    <div className="col-sm-3"><img src="/imgs/gallery/IMG_2669.JPG" alt="" /></div>
                    <div className="col-sm-3"><img src="/imgs/gallery/IMG_2690.JPG" alt="" /></div>
                    <div className="col-sm-3"><img src="/imgs/gallery/IMG_2714.JPG" alt="" /></div>
                    </div><div className="row">
                    <div className="col-sm-3"><img src="/imgs/gallery/IMG_2748.JPG" alt="" /></div>
                    <div className="col-sm-3"><img src="/imgs/gallery/IMG_2770.JPG" alt="" /></div>
                    <div className="col-sm-3"><img src="/imgs/gallery/IMG_2854.JPG" alt="" /></div>
                    <div className="col-sm-3"><img src="/imgs/gallery/IMG_2855.JPG" alt="" /></div>
                    </div><div className="row">
                    <div className="col-sm-3"><img src="/imgs/gallery/IMG_2856.JPG" alt="" /></div>
                    <div className="col-sm-3"><img src="/imgs/gallery/IMG_2859.JPG" alt="" /></div>
                    <div className="col-sm-3"><img src="/imgs/gallery/IMG_2863.JPG" alt="" /></div>
                    <div className="col-sm-3"><img src="/imgs/gallery/IMG_2865.JPG" alt="" /></div>
                    </div><div className="row">
                    <div className="col-sm-3"><img src="/imgs/gallery/IMG_2874.JPG" alt="" /></div>
                    <div className="col-sm-3"><img src="/imgs/gallery/IMG_2879.JPG" alt="" /></div>
                    <div className="col-sm-3"><img src="/imgs/gallery/IMG_2893.JPG" alt="" /></div>
                    <div className="col-sm-3"><img src="/imgs/gallery/IMG_2920.JPG" alt="" /></div>
                    </div><div className="row">
                    <div className="col-sm-3"><img src="/imgs/gallery/IMG_2401.JPG" alt="" /></div>
                    </div>
            <div className="row">
              <div className="col-sm-12" style={{alignContent: "center",alignItems: "center"}}>
                <div className="button">
                      <ul className="pagination">
                        <Link to="/gallery" href="index.html" class="active" style={{marginRight: "1px !important"}}><li>1</li></Link>
                        <Link to="/gallery1" href="gal2.html" style={{marginLeft: "1px!importan"}}><li>2</li></Link>
                      </ul>
                    </div>
              </div>
          </div>
         </div>

         <Link to="/gallery">
                <button className="back">
                        <i className="fa fa-arrow-left"></i>
                    </button>
                </Link> 

        </div>
    );
}

export default Gallery2;
