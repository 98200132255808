import React from 'react';
import SignUp from '../../components/SignUp/SignUp';

// import '../scss/login_page.scss';
// import '../scss/background_stars.scss';


function LoginPage(){
    return(
        <div className="signup-page">
            <div id="stars"></div>
            <div id="stars2"></div>
            <div id="stars3"></div>  

            

            <SignUp />
        </div>
    );
}

export default LoginPage;
