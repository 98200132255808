import React, {useEffect, useState} from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
} from "react-router-dom";
// import './scss/spons_page.scss';
import LandingPage from './screens/Landing/LandingPage';
import Events from './screens/Event/Events';
import EventPage from './screens/Event/EventDetail/EventPage';
import SponsorPage from './screens/Sponsor/SponsorPage';
import TeamPage from './screens/Team/TeamPage';
import ContactUs from './components/ContactUs/ContactUs';
import LoginPage from './screens/Login/LoginPage';
import SignupPage from './screens/SingUp/SignupPage';
import Gallery1 from './screens/Gallery/Gallery1';
import Gallery2 from './screens/Gallery/Gallery2';
import About from './screens/About/About';
import events from './data/event.json';
import guests from './data/guest.json';
import workshops from './data/workshop.json';
import TempBack from "./components/Backgrounds/Shuttle";
import initAnimation from "./components/Backgrounds/Shuttle/script";
import StudentAmbassador from "./screens/StudentAmbassador";
import StudentAmbassadorRegistration from "./screens/StudentAmbassadorRegistration";
import Workshops from "./screens/Workshop/Workshops";
import Guests from "./screens/Workshop/Guests";
import WorkshopPage from './screens/Workshop/WorkshopDetail/WorkshopPage';
import Dashboard from "./screens/Dashboard";
import Merchandise from "./screens/Merchandise";
import Payment from "./screens/Payment";

// import QuizPage from './screens/QuizPage';


function App() {

    // useEffect(()=>{
    //     initAnimation(true);
    // },[]);

    const [clicked, setClicked] = useState(false);

    function handleClick() {
        setClicked(!clicked);
    }

    return (
        <React.Fragment>
            {/*<TempBack/>*/}
            <Router>
                <Switch>
                    <Route exact path="/">
                        <LandingPage condition={clicked} click={handleClick}/>
                    </Route>
                    <Route exact path="/events">
                        <Events/>
                    </Route>

                    <Route exact path="/sponsors">
                        <SponsorPage/>
                    </Route>

                    <Route exact path="/team">
                        <TeamPage/>
                    </Route>

                    <Route exact path="/gallery">
                        <Gallery1/>
                    </Route>

                    <Route exact path="/gallery1">
                        <Gallery2/>
                    </Route>

                    <Route exact path="/about">
                        <About/>
                    </Route>

                    <Route exact path="/contact">
                        <ContactUs/>
                    </Route>
                    <Route exact path="/login">
                        <LoginPage/>
                    </Route>
                    <Route exact path="/signup">
                        <SignupPage/>
                    </Route>
                    <Route exact path="/dashboard">
                        <Dashboard/>
                    </Route>

                    {/*<Route exact path="/events/hoverpod">*/}
                    {/*    /!* {loading?"loading":(hasError?"error":<EventPage event={response.hoverpod}/>)} *!/*/}
                    {/*    <EventPage event={events.hoverpod}/>*/}
                    {/*</Route>*/}
                    {/*<Route exact path="/events/liftoff">*/}
                    {/*    /!* {loading?"loading":(hasError?"error":<EventPage event={response.liftoff}/>)} *!/*/}
                    {/*    <EventPage event={events.liftoff}/>*/}
                    {/*</Route>*/}
                    <Route exact path="/spacequiz">
                        {/* {loading?"loading":(hasError?"error":<EventPage event={response.spacequiz}/>)} */}
                        <EventPage event={events.spacequiz}/>
                    </Route>
                    <Route exact path="/astrophotography">
                        {/* {loading?"loading":(hasError?"error":<EventPage event={response.spacequiz}/>)} */}
                        <EventPage event={events.astrophotography}/>
                    </Route>
                    <Route exact path="/lettersfrommoon">
                        {/* {loading?"loading":(hasError?"error":<EventPage event={response.spacequiz}/>)} */}
                        <EventPage event={events.lettersfrommoon}/>
                    </Route>
                    <Route exact path="/paperpresentation">
                        <EventPage event={events.paperpresentation}/>
                    </Route>
                    <Route exact path="/casestudy">
                        <EventPage event={events.casestudy}/>
                    </Route>
                    <Route exact path="/scienceofpaperfolding">
                        <EventPage event={events.scienceofpaperfolding}/>
                    </Route>
                    <Route exact path="/designeer">
                        <EventPage event={events.designeer}/>
                    </Route>
                    <Route exact path="/treasure">
                        <EventPage event={events.treasure}/>
                    </Route>
                    <Route exact path="/data">
                        <EventPage event={events.data}/>
                    </Route>
                    <Route exact path="/astrobiological">
                        <WorkshopPage workshop={guests.astrobiological}/>
                    </Route>
                    <Route exact path="/extraterrestrial">
                        <WorkshopPage workshop={guests.extraterrestrial}/>
                    </Route>
                    <Route exact path="/planetary">
                        <WorkshopPage workshop={guests.planetary}/>
                    </Route>
                    <Route exact path="/exoplanets">
                        <WorkshopPage workshop={guests.exoplanets}/>
                    </Route>
                    <Route exact path="/hanle">
                        <WorkshopPage workshop={workshops.hanle}/>
                    </Route>
                    <Route exact path="/bigdata">
                        <WorkshopPage workshop={workshops.bigdata}/>
                    </Route>
                    {/*<Route exact path="/events/paperfolding">*/}
                    {/*    /!* {loading?"loading":(hasError?"error":<EventPage event={response.paperfolding}/>)} *!/*/}
                    {/*    <EventPage event={events.paperfolding}/>*/}
                    {/*</Route>*/}
                    {/*<Route exact path="/events/paperpresentation">*/}
                    {/*    /!* {loading?"loading":(hasError?"error":<EventPage event={response.paperpresentation}/>)} *!/*/}
                    {/*    <EventPage event={events.paperpresentation}/>*/}
                    {/*</Route>*/}
                    {/*<Route exact path="/events/designeer">*/}
                    {/*    /!* {loading?"loading":(hasError?"error":<EventPage event={response.designeer}/>)} *!/*/}
                    {/*    <EventPage event={events.designeer}/>*/}
                    {/*</Route>*/}
                    {/*<Route exact path="/events/casestudy">*/}
                    {/*    /!* {loading?"loading":(hasError?"error":<EventPage event={response.casestudy}/>)} *!/*/}
                    {/*    <EventPage event={events.casestudy}/>*/}
                    {/*</Route>*/}
                    <Route exact path="/sa_detail">
                        <StudentAmbassador />
                    </Route>
                    <Route exact path="/sa_register">
                        <StudentAmbassadorRegistration />
                    </Route>
                    <Route exact path="/workshops">
                        <Workshops />
                    </Route>
                    <Route exact path="/guests">
                        <Guests />
                    </Route>
                    <Route exact path="/merchandise">
                        <Merchandise />
                    </Route>
                    {/* <Route exact path="/quiz">
                 <QuizPage/>
            </Route> */}

                </Switch>
            </Router>
        </React.Fragment>
    );
}

export default App;
