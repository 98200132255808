import React, { useEffect }  from 'react';
import {Link} from 'react-router-dom';
import AOS from "aos";
import "aos/dist/aos.css";


function EventTile(props){
    // const [mobile,setMobile] = useState(false);

    useEffect(() => {
        AOS.init();
        AOS.refresh();

        // window.addEventListener('resize',function(){
        //     setMobile(function(){
        //         return window.innerWidth<450;
        //     });
        // });
        // window.addEventListener('load',function(){
        //     setMobile(function(){
        //         return window.innerWidth<450;
        //     });
        // });
        
      });



    return(
        <div className="event-container">
            <img id="pc" data-aos="fade-right" data-aos-duration="900" src={`/imgs/events/${props.eventImg}`} alt="Avatar" className="event-img" />
            <div className="event-info">
                <blockquote  className="event" >
      
                <h1 data-aos="fade-left" ><em>{props.eventName}</em></h1>
                <img id="mobile" data-aos="fade-right" data-aos-duration="900" src={`/imgs/events/${props.eventImg}`} alt="Avatar" className="event-img" />
                <p data-aos="fade-left" data-aos-duration="700">{props.eventDesc} </p>
                </blockquote>
                <Link to={`/${props.eventLink}`}>
                    <button data-aos="fade-left" data-aos-duration="700" onClick={function(){window.scrollTo(0, 0);}} className="event-button">Know More!</button>
                </Link>
            </div>
        </div>
    );
}

export default EventTile;
