import React, {useEffect} from 'react';
import {Link} from 'react-router-dom';
import ToggleNav from '../ToggleNav';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
// import '../scss/background_stars.scss';
// import '../scss/contact_us.scss';
import HeadsetMicIcon from '@material-ui/icons/HeadsetMic';
import AOS from "aos";
import "aos/dist/aos.css";
import ComingSoon from "../ComingSoon";

function ContactUs() {
    useEffect(() => {
        AOS.init();
        AOS.refresh();
    });

    const isActive = true;

    if(!isActive){
        return <ComingSoon title={''}/>
    }

    return (
        <div className="contact-us-page">
            <div id='stars'></div>
            <div id='stars2'></div>
            <div id='stars3'></div>
            {/* <div id='horizon'>
  <div className='glow'></div>
</div> */}
            {/* <div id='earth'></div> */}
            <Link to="/">
                <button className="back">
                    <i className="fa fa-arrow-left"></i>
                </button>
            </Link>

            <ToggleNav/>
            <div className="contact-us">

                <div id='title'>
                    CONTACT US
                </div>
                <div id='subtitle'>
                    {/* <h2>Reach us at :</h2>  */}
                    <h3>General Queries</h3>
                    <p data-aos="fade-left"><HeadsetMicIcon/>Sai Swaroop : <span>+91 9390180656</span></p>
                    <p data-aos="fade-left"><HeadsetMicIcon/>Amritakshya : <span>+91 8811869007</span></p>

                    <h3>Events Queries</h3>
                    <p data-aos="fade-left"><MailOutlineIcon />Mail Us at <span>events@nssc.in</span></p>

                </div>
            </div>

        </div>
    );
}

export default ContactUs;
